import React from 'react';
import {
  AppBarItem,
  AppBarNavLink,
} from '@leagueplatform/web-common-components';
import { useIntl } from '@leagueplatform/locales';
import { Link } from '@leagueplatform/routing';
import { EVENT_NAME, trackAnalyticsEvent } from '@leagueplatform/analytics';
import {
  Image,
  TextAction,
  queryHelpers,
  useMediaQuery,
} from '@leagueplatform/genesis-core';
import {
  HeaderNavBar,
  MobileNavBar,
  HEADER_NAV_BAR_DEFAULT_HEIGHT,
} from '@premera-web/shared-module';
import logoWithText from 'assets/logo-with-text.svg';
import { APP_MANAGED_ROUTES } from 'common/app-managed-routes';
import { UserDropdown } from './user-dropdown/user-dropdown.component';
import { useNavLinksList } from './use-nav-links-list.hook';
import { useUserDropdownComponent } from './user-dropdown/use-user-dropdown.hook';

export const APP_HEADER_NAV_DEFAULT_HEIGHT = HEADER_NAV_BAR_DEFAULT_HEIGHT;

export const AppHeaderNavBar = React.memo(() => {
  const { formatMessage } = useIntl();
  const { navLinks } = useNavLinksList();
  const sendNavBarAnalyticsEvent = (action: string) => () =>
    trackAnalyticsEvent(EVENT_NAME.NAVIGATION_TAB_CLICKED, {
      screen_name: 'Navigation Bar',
      detail: action,
    });

  const isMobile = useMediaQuery(queryHelpers.down('mobileLandscape'));

  const { data } = useUserDropdownComponent();

  const logo = (
    <TextAction
      as={Link}
      to={APP_MANAGED_ROUTES.HOME}
      onClick={sendNavBarAnalyticsEvent('HOME')}
    >
      <Image
        src={logoWithText}
        height="40px"
        alt="LifeWise Assurance Company"
      />
    </TextAction>
  );

  return (
    <HeaderNavBar>
      {!isMobile && (
        <>
          <AppBarItem margin="auto" paddingRight="two">
            {logo}
          </AppBarItem>

          {/* App Nav Home & Capability Areas */}
          {navLinks.map((navLink) => (
            <AppBarNavLink
              to={navLink.to}
              key={navLink.message_id}
              onClick={sendNavBarAnalyticsEvent(navLink.message_id)}
              color="onSurface.text.subdued"
              hoverStyle={{ color: 'onSurface.text.primary' }}
              minWidth="85px"
              justifyContent="center"
              fontWeight="bold"
            >
              {formatMessage({ id: navLink.message_id })}
            </AppBarNavLink>
          ))}

          {/* Spacer */}
          <AppBarItem aria-hidden flexGrow={20} />

          {/* App Nav User Dropdown */}
          <AppBarItem minWidth="150px" justifyContent="end">
            <UserDropdown />
          </AppBarItem>
        </>
      )}
      {isMobile && (
        <MobileNavBar
          navLinks={navLinks}
          settingsLinks={data.mobileNavDrawerLinksConfig}
          logo={logo}
        />
      )}
    </HeaderNavBar>
  );
});
