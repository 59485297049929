import FeatureHighlightsBackground from 'assets/feature-highlights-background.png';
import ClaimIconOral from 'assets/claims/claim-icon-oral.svg';
import ClaimIconInstitutional from 'assets/claims/claim-icon-institutional.svg';
import calendar from 'assets/wallet/calendar.svg';
import ErrorEndOfRoad from 'assets/error-end-of-road.svg';

const WALLET_OVERRIDES = {
  CALENDAR: calendar,
};

const CLAIMS_OVERRIDES = {
  ORAL: ClaimIconOral,
  INSTITUTIONAL: ClaimIconInstitutional,
  CLAIMS_LIST_EMPTY: ErrorEndOfRoad,
};

const ONBOARDING_OVERRIDES = {
  FEATURE_HIGHLIGHT_BACKGROUND: FeatureHighlightsBackground,
};

const COMMON_OVERRIDES = {
  WEB_COMMON_ERROR_STATE_IMAGE: ErrorEndOfRoad,
};

export const ASSET_OVERRIDES = {
  ...WALLET_OVERRIDES,
  ...CLAIMS_OVERRIDES,
  ...ONBOARDING_OVERRIDES,
  ...COMMON_OVERRIDES,
};
