import React from 'react';
import {
  ParagraphText,
  StatusBanner,
  TextAction,
} from '@leagueplatform/genesis-core';
import { useIntl } from '@leagueplatform/locales';
import { EXTERNAL_LINKS_NONPROD, EXTERNAL_LINKS_PROD } from 'common/links';
import { isProduction } from '@leagueplatform/core';

export const StatusBannerMasonryWidget = ({
  isClaims,
}: {
  isClaims?: boolean;
}) => {
  const { formatMessage } = useIntl();
  const isProd = isProduction();

  let url;

  if (isProd && isClaims) {
    url = EXTERNAL_LINKS_PROD.MEMBER_CLAIMS;
  }

  if (!isProd && isClaims) {
    url = EXTERNAL_LINKS_NONPROD.MEMBER_CLAIMS;
  }

  if (isProd && isClaims === undefined) {
    url = EXTERNAL_LINKS_PROD.ACCOUNT_MANAGEMENT;
  }

  if (!isProd && isClaims === undefined) {
    url = EXTERNAL_LINKS_NONPROD.ACCOUNT_MANAGEMENT;
  }

  return (
    <StatusBanner
      title={
        isClaims
          ? formatMessage({ id: 'STR_CLAIMS_STATUS_BANNER_HEADING' })
          : formatMessage({ id: 'STR_HOME_STATUS_BANNER_HEADING' })
      }
      status="info"
      css={{ minHeight: '80px' }}
    >
      <ParagraphText size="sm" css={{ paddingTop: '$half' }}>
        {isClaims
          ? formatMessage({ id: 'STR_CLAIMS_STATUS_BANNER_BODY' })
          : formatMessage({ id: 'STR_HOME_STATUS_BANNER_BODY' })}
      </ParagraphText>
      <TextAction
        size="medium"
        href={url}
        indicator="externalLink"
        target="_blank"
        referrerPolicy="no-referrer"
        css={{
          width: 'fit-content',
        }}
      >
        {isClaims
          ? formatMessage({ id: 'STR_CLAIMS_STATUS_BANNER_ACTION_LINK' })
          : formatMessage({ id: 'STR_HOME_STATUS_BANNER_ACTION_LINK' })}
      </TextAction>
    </StatusBanner>
  );
};
