import { FooterLinkProps, AssetLinkProps } from '@premera-web/shared-module';
import GooglePlayBadge from 'assets/google-play-badge.svg';
import AppleStoreBadge from 'assets/apple-store-badge.svg';
import { useIntl } from '@leagueplatform/locales';
import { EXTERNAL_LINKS } from 'common/links';

// TODO: GET REAL Link Values: https://everlong.atlassian.net/browse/PREM-142
const APP_DOWNLOAD_ASSET_LINKS: AssetLinkProps[] = [
  {
    src: AppleStoreBadge,
    url: 'https://apps.apple.com/us/app/id1521351182',
    alt: 'apple store app download link',
  },
  {
    src: GooglePlayBadge,
    url: 'https://play.google.com/store/apps/details?id=com.lifewiseac.android&hl=en_US',
    alt: 'google play app download link',
  },
];

export const useAppFooterLinks = () => {
  const { formatMessage } = useIntl();

  const footerLinks: FooterLinkProps[] = [
    {
      text: formatMessage({ id: 'STR_ABOUT' }),
      url: EXTERNAL_LINKS.ABOUT,
    },
    {
      text: formatMessage({ id: 'STR_CONTACT_US' }),
      url: EXTERNAL_LINKS.CONTACT_US,
    },
    {
      text: formatMessage({ id: 'STR_FORMS' }),
      url: EXTERNAL_LINKS.FORMS,
    },
    {
      text: formatMessage({ id: 'STR_GET_HELP' }),
      url: EXTERNAL_LINKS.GET_HELP,
    },
  ];

  const secondaryFooterLinks: FooterLinkProps[] = [
    {
      text: formatMessage({ id: 'STR_TERMS' }),
      url: EXTERNAL_LINKS.TERMS,
    },
    {
      text: formatMessage({ id: 'STR_PRIVACY' }),
      url: EXTERNAL_LINKS.PRIVACY,
    },
    {
      text: formatMessage({ id: 'STR_MEDICAL_POLICIES' }),
      url: EXTERNAL_LINKS.MEDICAL_POLICIES,
    },
    {
      text: formatMessage({ id: 'STR_PARTNERS' }),
      url: EXTERNAL_LINKS.PARTNERS,
    },
    {
      text: formatMessage({ id: 'STR_TRANSPARENCY' }),
      url: EXTERNAL_LINKS.TRANSPARENCY,
    },
    {
      text: formatMessage({ id: 'STR_FRAUD' }),
      url: EXTERNAL_LINKS.FRAUD,
    },
    {
      text: formatMessage({ id: 'STR_CODE_OF_CONDUCT' }),
      url: EXTERNAL_LINKS.CODE_OF_CONDUCT,
    },
    {
      text: formatMessage({ id: 'STR_DATA_COLLECTION' }),
      url: EXTERNAL_LINKS.DATA_COLLECTION,
    },
  ];

  return {
    footerLinks,
    secondaryFooterLinks,
    appDownloadLinks: APP_DOWNLOAD_ASSET_LINKS,
  };
};
