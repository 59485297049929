/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import { useIntl } from '@leagueplatform/locales';
import {
  Button,
  HeadingText,
  ImageMessage,
  Modal,
  ParagraphText,
  StackLayout,
  TextAction,
} from '@leagueplatform/genesis-core';
import { PopupMessageModalProps } from './popup-message-modal.types';

export const PopUpMessageModal = ({
  content: {
    image,
    headingId,
    headingText,
    bodyId,
    bodyText,
    bodyTwoId,
    bodyTwoText,
    cancelButton,
    submitButton,
    innerContainerStyling,
    modalContentProps,
  },
  modalRootProps,
}: PopupMessageModalProps) => {
  const { formatMessage } = useIntl();

  const formatMessageWithLink = (strId?: string) =>
    formatMessage(
      { id: strId },
      {
        a: (text) =>
          (
            <TextAction
              as="a"
              href="https://student.lifewiseac.com/portals/member/Information/MyAccount/"
              target="_blank"
            >
              {text}
            </TextAction>
          ) as unknown as string,
      },
    );

  return (
    <Modal.Root {...modalRootProps}>
      <Modal.Content
        forceModalFocus
        padding="$one $oneAndHalf"
        width={{ '@initial': '600px', '@mobile': '100vw' }}
        {...modalContentProps}
      >
        <StackLayout spacing="$half" css={innerContainerStyling}>
          {image && (
            <StackLayout css={{ width: '100%' }} horizontalAlignment="center">
              <ImageMessage image={image} imageHeight={160} />
            </StackLayout>
          )}
          <Modal.Title>
            <HeadingText size="lg" level="3">
              {headingText || formatMessage({ id: headingId })}
            </HeadingText>
          </Modal.Title>
          <Modal.Description>
            <>
              {(bodyId || bodyText) && (
                <ParagraphText css={{ marginBottom: '$oneAndHalf' }}>
                  {bodyText || formatMessageWithLink(bodyId)}
                </ParagraphText>
              )}

              {(bodyTwoId || bodyTwoText) && (
                <ParagraphText emphasis="subtle">
                  {bodyTwoText || formatMessageWithLink(bodyTwoId)}
                </ParagraphText>
              )}

              <StackLayout
                orientation="horizontal"
                horizontalAlignment={
                  submitButton
                    ? {
                        '@initial': 'end',
                        '@mobile': 'center',
                      }
                    : 'center'
                }
                spacing="$one"
                css={{ width: '100%', marginTop: '$half', flexWrap: 'wrap' }}
              >
                {cancelButton && (
                  <Button
                    onClick={cancelButton.onClick}
                    priority="secondary"
                    quiet={!!submitButton}
                  >
                    {cancelButton.text ||
                      formatMessage({ id: cancelButton.id })}
                  </Button>
                )}
                {submitButton && (
                  <Button onClick={submitButton.onClick}>
                    {submitButton.text ||
                      formatMessage({ id: submitButton.id })}
                  </Button>
                )}
              </StackLayout>
            </>
          </Modal.Description>
        </StackLayout>
      </Modal.Content>
    </Modal.Root>
  );
};
