import { Spinner, StackLayout } from '@leagueplatform/genesis-core';

export const LoadingSpinner = () => (
  <StackLayout
    horizontalAlignment="center"
    verticalAlignment="center"
    css={{
      width: '100%',
      height: '100%',
    }}
  >
    <Spinner
      thickness={1.5}
      size="$three"
      tint="$interactiveActionPrimary"
      loading
    />
  </StackLayout>
);
