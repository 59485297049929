import React from 'react';
import {
  Box,
  Button,
  GDSStyleObject,
  TextAction,
} from '@leagueplatform/genesis-core';
import { isAbsoluteUrl } from '@leagueplatform/web-common';
import { NavLink } from '@leagueplatform/routing';

import { OpensInANewTabMessage } from '@leagueplatform/web-common-components';
import { Link } from '../nav-types.types';

export const defaultMobileNavLinkStyles: GDSStyleObject = {
  '&&': {
    display: 'flex',
    alignItems: 'center',
    color: '$onSurfaceTextSubdued',
    typography: '$subtitleTwo',
    borderRadius: '$medium',
    padding: '$half',
    '&[aria-current="page"], &.active': {
      color: '$interactiveActionPrimary',
      borderBottomColor: '$interactiveActionPrimary',
    },
    '&:hover': {
      backgroundColor: 'transparent',
      color: '$interactiveActionHovered',
    },
    '.GDS-icon': {
      marginLeft: '$half',
    },
  },
};

type MobileNavLinkProps = {
  children: React.ReactNode;
  linkData: Link;
  onLinkClick: () => void;
};

export const MobileNavLink = ({
  children,
  linkData,
  onLinkClick,
}: MobileNavLinkProps) => {
  const { text, color } = linkData;
  if ('to' in linkData) {
    const { to } = linkData;

    if (isAbsoluteUrl(linkData?.to)) {
      // Nav item is an external link
      return (
        <TextAction
          as="a"
          href={to}
          target="_blank"
          rel="noopener noreferrer"
          css={defaultMobileNavLinkStyles}
          onClick={onLinkClick}
          indicator="externalLink"
        >
          {children}
          <OpensInANewTabMessage />
        </TextAction>
      );
    }
    // Nav item is an internal link
    return (
      <Box
        as={NavLink}
        to={to}
        id={`mobile-nav-link-${text}`}
        css={{
          ...defaultMobileNavLinkStyles,
          ...(color && { color: `${color} !important` }),
        }}
        onClick={onLinkClick}
      >
        {children}
      </Box>
    );
  }

  // Nav item is a button link
  if ('onClick' in linkData)
    return (
      <Box as="li" css={{ width: '100%' }}>
        <Button
          quiet
          size="medium"
          priority="tertiary"
          css={defaultMobileNavLinkStyles}
          onClick={onLinkClick}
        >
          {children}
        </Button>
      </Box>
    );

  return null;
};
