import { Auth0Error } from '@premera-web/shared-module';

export enum ERROR_CODES {
  INELIGIBLE = '0401',
}

export const parseAuth0ErrorCode = (authError: Auth0Error) => {
  const { description } = authError;
  if (!description) {
    return null;
  }

  // Look for initial digits in brackets regex of pattern, example: [1234] - some error message.
  const regex = /^\[([\d]+)\].*/;
  const result = regex.exec(description);

  return result ? result[1] : null;
};

export const isIneligibleAuthError = (authError: Auth0Error) =>
  parseAuth0ErrorCode(authError) === ERROR_CODES.INELIGIBLE;

export const buildErrorUrlParams = (authError: Auth0Error) => {
  const urlErrorParams = new URLSearchParams();

  urlErrorParams.append('error', authError.type);
  if (authError.description) {
    urlErrorParams.append('error_description', authError.description);
  }

  return urlErrorParams.toString();
};
