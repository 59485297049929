import React, { useEffect } from 'react';
import { Box, StackLayout } from '@leagueplatform/genesis-core';
import { useHistory } from '@leagueplatform/routing';
import { useAuth } from '@leagueplatform/auth-utils';
import { LoadingIndicator } from '@leagueplatform/web-common-components';
import { LandingPageProps } from './landing.page.types';
import { SignInSection } from './sign-in-section.component';

export const LandingPage = ({
  backgroundImage,
  logoImage,
  primaryButton,
  onAuthenticated,
  onAuthError,
  shouldDisplayErrorCaption = false,
}: LandingPageProps) => {
  const { isAuthenticated, appState, error } = useAuth();
  const history = useHistory();

  useEffect(() => {
    if (isAuthenticated) {
      onAuthenticated(appState);
    }
  }, [history, isAuthenticated, appState, onAuthenticated]);

  useEffect(() => {
    if (error) {
      onAuthError(error);
    }
  }, [error, onAuthError]);

  return (
    <StackLayout
      verticalAlignment="stretch"
      horizontalAlignment="stretch"
      orientation={{
        '@initial': 'horizontal',
        '@mobile': 'vertical',
        '@mobileLandscape': 'vertical',
      }}
      css={{
        minWidth: '100vw',
        minHeight: '100vh',
      }}
    >
      {isAuthenticated === undefined && <LoadingIndicator />}
      {isAuthenticated !== undefined && (
        <>
          <StackLayout
            css={{
              height: '100vh',
              width: '50vw',
              '@mobileLandscape': {
                minHeight: '60vh',
                width: '100vw',
              },
              '@mobile': {
                minHeight: '60vh',
                width: '100vw',
              },
            }}
          >
            <Box
              css={{
                backgroundImage: `url(${backgroundImage})`,
                width: '100%',
                height: '100%',
                backgroundPosition: '50% top',
                backgroundSize: 'cover',
              }}
            />
          </StackLayout>
          <SignInSection
            logo={logoImage}
            errorMessage={shouldDisplayErrorCaption ? error?.description : null}
            signInButton={primaryButton}
          />
        </>
      )}
    </StackLayout>
  );
};
