import { Footer } from '@premera-web/shared-module';
import { Disclaimer } from './disclaimer-section.component';
import { useAppFooterLinks } from './use-app-footer-links.hook';

export const AppFooter = () => {
  const { footerLinks, secondaryFooterLinks, appDownloadLinks } =
    useAppFooterLinks();
  return (
    <Footer
      footerLinks={footerLinks}
      secondaryFooterLinks={secondaryFooterLinks}
      disclaimer={<Disclaimer />}
      footerAppDownloadAssets={appDownloadLinks}
      backgroundColor="$decorativeBrandPrimaryDarkest"
      textColor="$onSurfaceTextReversed"
    />
  );
};
