import { useQuery } from 'react-query';
import { PartnerTokenData, getPartnerToken } from '../api/get-partner-token';

export const useGetPartnerToken = (partnerId: string) =>
  useQuery<PartnerTokenData, Error>(
    ['GET_IDENTITY_PARTNER_TOKEN'],
    () => getPartnerToken(partnerId),
    {
      cacheTime: 2700000, // Setting cache timer for 45 minutes to fetch new identity token
    },
  );
