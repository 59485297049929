import { useEffect } from 'react';
import { StandaloneAuth } from '@leagueplatform/auth-standalone';
import { LoadingIndicator } from '@leagueplatform/web-common-components';
import { useGetPartnerToken } from '@premera-web/shared-module';
import { PARTNER_ID } from 'common/constants';
import { captureAppError } from '@leagueplatform/observability';
import { getIdpPartnerLogoutUrl } from 'common/env';

export const POST_LOGOUT_REDIRECT_URI_QUERY_KEY = 'post_logout_redirect_uri';
export const ID_TOKEN_HINT_QUERY_KEY = 'id_token_hint';

export const AuthViewLogout = () => {
  const { data, isLoading, isError, error } = useGetPartnerToken(PARTNER_ID);

  useEffect(() => {
    if (!isLoading) {
      if (error) {
        captureAppError(error);
      }

      let returnToUrl = window.location.origin;
      const idpPartnerLogoutUrl = getIdpPartnerLogoutUrl();
      // If we can get id token, pass it to IDP logout URL as query parameter
      if (!isError && data?.idToken && idpPartnerLogoutUrl) {
        const newUrl = new URL(idpPartnerLogoutUrl);
        newUrl.searchParams.append(ID_TOKEN_HINT_QUERY_KEY, data.idToken);
        newUrl.searchParams.append(
          POST_LOGOUT_REDIRECT_URI_QUERY_KEY,
          window.location.origin,
        );
        returnToUrl = newUrl.toString();
      }

      StandaloneAuth.client.logout({
        returnTo: returnToUrl,
      });
    }
  }, [data, isLoading, isError, error]);

  return <LoadingIndicator />;
};

export const AuthViewLogin = () => {
  useEffect(() => {
    StandaloneAuth.client.loginWithRedirect();
  }, []);

  return <LoadingIndicator />;
};
