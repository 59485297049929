export const EXTERNAL_LINKS = {
  ABOUT: 'https://students.lifewiseac.com/about/',
  CONTACT_US: 'https://students.lifewiseac.com/contact/',
  FORMS: 'https://students.lifewiseac.com/forms/',
  GET_HELP: 'https://students.lifewiseac.com/help/',
  TERMS: 'https://students.lifewiseac.com/terms/',
  PRIVACY: 'https://students.lifewiseac.com/privacy/',
  MEDICAL_POLICIES:
    'https://students.lifewiseac.com/medical-policies-disclaimer/',
  PARTNERS: 'https://students.lifewiseac.com/partners/',
  TRANSPARENCY: 'https://students.lifewiseac.com/transparency-coverage/',
  FRAUD: 'https://students.lifewiseac.com/fraud-abuse/',
  CODE_OF_CONDUCT:
    'https://student.lifewiseac.com/documents/034216_TaglineStudentInsurance.pdf',
  DATA_COLLECTION: 'https://students.lifewiseac.com/data-collection/',
  APPLE_STORE_APP: 'https://apps.apple.com/us/app/id1521351182',
  GOOGLE_PLAY_STORE_APP:
    'https://play.google.com/store/apps/details?id=com.lifewiseac.android&hl=en_US',
  LANGUAGES_AND_DISCRIMINATION:
    'https://student.lifewiseac.com/documents/037404.pdf',
  OPEN_EXTERNAL_LINK: 'Opens an external link',
};

export const EXTERNAL_LINKS_PROD = {
  ACCOUNT_MANAGEMENT:
    'https://student.lifewiseac.com/portals/member/Information/MyAccount',
  ENROLL_DEPENDENTS: 'https://student.lifewiseac.com/portals/member/Enroll/',
  GO_PAPERLESS:
    'https://student.lifewiseac.com/portals/member/Information/Paperless',
  MEMBER_CLAIMS: 'https://student.lifewiseac.com/portals/member/Claims',
};

export const EXTERNAL_LINKS_NONPROD = {
  ACCOUNT_MANAGEMENT:
    'https://student.lifewiseac.acpt/portals/member/account/logon',
  ENROLL_DEPENDENTS: 'https://student.lifewiseac.acpt/portals/member/Enroll/',
  GO_PAPERLESS:
    'https://student.lifewiseac.acpt/portals/member/Information/Paperless/',
  MEMBER_CLAIMS: 'https://student.lifewiseac.com/portals/member/Claims',
};
