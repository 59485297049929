import React from 'react';
import { useIntl } from '@leagueplatform/locales';
import {
  HeadingText,
  StackLayout,
  Icon,
  Modal,
  Divider,
  Box,
} from '@leagueplatform/genesis-core';
import { useLocation, NavLink } from '@leagueplatform/routing';

import {
  sendCapabilityNavLinkEvent,
  sendUserOptionEvent,
} from '../../../utilities/analytics-helpers';
import {
  MobileNavLink,
  defaultMobileNavLinkStyles,
} from './mobile-nav.links.component';
import { MobileNavDrawerLinksConfig, NavItem } from '../nav-types.types';

export type MobileNavDrawerProps = {
  closeModal: () => void;
  navLinks: NavItem[];
  settingsLinks: MobileNavDrawerLinksConfig[];
};

export const MobileNavDrawer = ({
  closeModal,
  navLinks,
  settingsLinks,
}: MobileNavDrawerProps) => {
  const { formatMessage } = useIntl();
  const location = useLocation();
  const handleCapabilityNavLinkClick = (action: string) => {
    closeModal();
    sendCapabilityNavLinkEvent(action);
  };
  const mainNavLinks = navLinks.map(({ message_id, icon, to }: NavItem) => {
    const isActiveLink = location.pathname.startsWith(to as string);
    return (
      <Box
        as={NavLink}
        to={to as string}
        id={`${formatMessage({ id: message_id })}-link`}
        key={`${formatMessage({ id: message_id })}-link`}
        css={defaultMobileNavLinkStyles}
        onClick={() => handleCapabilityNavLinkClick(message_id)}
      >
        {icon && (
          <Icon
            icon={isActiveLink ? `${icon}Filled` : icon}
            css={{
              marginRight: '$half',
              color: '',
              '&[aria-current="page"], &.active': {
                color: '$interactiveActionPrimary',
                borderBottomColor: '$interactiveActionPrimary',
              },
              '&:hover': {
                backgroundColor: 'transparent',
                color: '$interactiveActionHovered',
              },
            }}
          />
        )}
        {formatMessage({ id: message_id })}
      </Box>
    );
  });

  return (
    <StackLayout as="nav" spacing="$one" horizontalAlignment="stretch">
      <Modal.Title>
        <HeadingText
          level="2"
          size="xl"
          css={{
            paddingLeft: '$half',
          }}
        >
          {formatMessage({ id: 'STR_MENU' })}
        </HeadingText>
      </Modal.Title>
      <StackLayout as="ul" spacing="$half" horizontalAlignment="stretch">
        {mainNavLinks}
      </StackLayout>
      {settingsLinks.map(({ heading, items }) => (
        <StackLayout
          spacing="$oneAndHalf"
          css={{
            width: '100%',
          }}
          key={`mobile_nav--${heading || items[0]?.text}`}
          horizontalAlignment="stretch"
        >
          <Divider css={{ margin: '0 $half', width: 'calc(100% - $half)' }} />
          <StackLayout spacing="$half" horizontalAlignment="stretch">
            {heading && (
              <HeadingText
                level="3"
                size="xl"
                css={{
                  paddingLeft: '$half',
                }}
              >
                {formatMessage({ id: heading })}
              </HeadingText>
            )}
            <StackLayout as="ul" spacing="$half" horizontalAlignment="stretch">
              {items.map((item) => {
                const { text } = item;
                const handleOptionClick = () => {
                  closeModal();
                  sendUserOptionEvent(text);
                  if ('onClick' in item) item.onClick();
                };

                return (
                  <MobileNavLink
                    linkData={item}
                    onLinkClick={handleOptionClick}
                    key={`mobile-nav-link--${text}`}
                  >
                    {formatMessage({ id: text })}
                  </MobileNavLink>
                );
              })}
            </StackLayout>
          </StackLayout>
        </StackLayout>
      ))}
    </StackLayout>
  );
};
