import { StandaloneAuth } from '@leagueplatform/auth-standalone';
import { useEffect, useState } from 'react';

export const useIsAuthenticated = () => {
  const [isAuthenticated, setAuthenticated] = useState<boolean | undefined>(
    () => undefined,
  );

  useEffect(() => {
    let isMounted: boolean = true;
    StandaloneAuth.client.isAuthenticated().then((authenticated) => {
      if (isMounted) {
        setAuthenticated(authenticated);
      }
    });

    // clean up subscription
    return () => {
      isMounted = false;
    };
  }, []);

  return { isAuthenticated };
};
