import React from 'react';
import {
  Box,
  GDSBoxProps,
  TextAction,
  styled,
} from '@leagueplatform/genesis-core';
import { useIntl } from '@leagueplatform/locales';

const SkipToMainContentBox = styled(Box, {
  position: 'absolute',
  zIndex: '$dropdown',
  '&:focus-within': {
    // include the focus outline when positioning
    left: '$quarter',
    top: '$quarter',
  },
});

export const SkipToMainContentLink = (props: GDSBoxProps<'div'>) => {
  const { formatMessage } = useIntl();

  return (
    // eslint-disable-next-line react/jsx-props-no-spreading
    <SkipToMainContentBox {...props}>
      <TextAction
        css={{
          padding: '$half',
          borderColor: '$onSurfaceBorderSubdued',
          borderWidth: 'thin',
          backgroundColor: '$surfaceBackgroundPrimary',
          '&&:focus': {
            outlineOffset: '0',
          },
        }}
        href="#main-content"
      >
        {formatMessage({ id: 'SKIP_TO_CONTENT_MESSAGE' })}
      </TextAction>
    </SkipToMainContentBox>
  );
};
