import { isProduction } from '@leagueplatform/core';
import { DropdownSection } from '@leagueplatform/web-common-components';
import { usePreferredFirstName } from '@premera-web/shared-module';
import { APP_MANAGED_ROUTES } from 'common/app-managed-routes';
import {
  EXTERNAL_LINKS_NONPROD,
  EXTERNAL_LINKS_PROD,
  EXTERNAL_LINKS,
} from 'common/links';

const settingsLogoutLink = {
  to: APP_MANAGED_ROUTES.SIGN_OUT,
  text: 'LOG_OUT',
};

export const useUserDropdownComponent = () => {
  const { data, isLoading, error } = usePreferredFirstName();
  const isProd = isProduction();
  const settingsLinks = [
    {
      text: 'STR_ACCOUNT_MANAGEMENT',
      to: isProd
        ? EXTERNAL_LINKS_PROD.ACCOUNT_MANAGEMENT
        : EXTERNAL_LINKS_NONPROD.ACCOUNT_MANAGEMENT,
      icon: 'tinyExternalLink',
      label: EXTERNAL_LINKS.OPEN_EXTERNAL_LINK,
    },
    {
      text: 'STR_ENROLL_DEPENDENTS',
      to: isProd
        ? EXTERNAL_LINKS_PROD.ENROLL_DEPENDENTS
        : EXTERNAL_LINKS_NONPROD.ENROLL_DEPENDENTS,
      icon: 'tinyExternalLink',
      label: EXTERNAL_LINKS.OPEN_EXTERNAL_LINK,
    },
    {
      text: 'STR_GO_PAPERLESS',
      to: isProd
        ? EXTERNAL_LINKS_PROD.GO_PAPERLESS
        : EXTERNAL_LINKS_NONPROD.GO_PAPERLESS,
      icon: 'tinyExternalLink',
      label: EXTERNAL_LINKS.OPEN_EXTERNAL_LINK,
    },
  ];

  const desktopUserDropdownConfig: DropdownSection[] = [
    settingsLinks.map((props) => ({
      ...props,
      linkProps: {
        fontWeight: 'bold',
        color: 'onSurface.text.subdued',
        target: '_blank',
      },
    })),
    [
      {
        ...settingsLogoutLink,
        linkProps: {
          fontWeight: 'bold',
          color: 'onSurface.text.critical',
        },
      },
    ],
  ];

  const mobileNavDrawerLinksConfig = [
    {
      items: settingsLinks,
    },
    {
      items: [
        {
          ...settingsLogoutLink,
          color: '$onSurfaceTextCritical',
        },
      ],
    },
  ];

  const dropdownMenuItemProps = {
    preferredFirstName: data?.preferredFirstName,
    desktopUserDropdownConfig,
    mobileNavDrawerLinksConfig,
  };

  return {
    data: dropdownMenuItemProps,
    isLoading,
    error,
  };
};
