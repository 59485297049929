/* eslint-disable import/no-extraneous-dependencies */
import { Route, Switch } from '@leagueplatform/routing';

import { AuthViewLogin, AuthViewLogout } from 'pages/auth/Auth.pages';

import { Home, WalletRoutes, ClaimsRoutes } from 'components/LazyRoutes';

import { getModulePath, LEAGUE_MODULE_NAMES } from '@leagueplatform/core';

import ErrorPage from 'pages/Error404';
import { PreLoginLanding } from 'pages/pre-login-landing.page';
import { APP_MANAGED_ROUTES } from 'common/app-managed-routes';
import { AuthSessionContainer } from './auth-session-container/auth-session-container';
import { PrivateRoutesContainer } from './private-routes-container/private-routes-container.component';

function AppRoutes() {
  return (
    <Switch>
      <Route path={APP_MANAGED_ROUTES.SIGN_IN} component={AuthViewLogin} />
      <Route path={APP_MANAGED_ROUTES.SIGN_OUT} component={AuthViewLogout} />
      <Route
        path={APP_MANAGED_ROUTES.LANDING_PAGE}
        exact
        component={PreLoginLanding}
      />
      <AuthSessionContainer>
        <PrivateRoutesContainer>
          <Switch>
            <Route path={APP_MANAGED_ROUTES.HOME} component={Home} key="home" />
            <Route
              path={getModulePath(LEAGUE_MODULE_NAMES.wallet)}
              component={WalletRoutes}
              key="wallet"
            />
            <Route
              path={getModulePath(LEAGUE_MODULE_NAMES.claims)}
              component={ClaimsRoutes}
              key="claims"
            />
            <Route component={ErrorPage} />
          </Switch>
        </PrivateRoutesContainer>
      </AuthSessionContainer>
    </Switch>
  );
}

export default AppRoutes;
