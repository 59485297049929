import { StackLayout } from '@leagueplatform/genesis-core';
import { ErrorState } from '@premera-web/shared-module';

function ErrorPage() {
  return (
    <StackLayout
      horizontalAlignment="center"
      verticalAlignment="center"
      css={{
        width: '100vw',
        height: '100vh',
      }}
    >
      <ErrorState headingId="SORRY_WE_CANT_FIND_PAGE" />
    </StackLayout>
  );
}

export default ErrorPage;
