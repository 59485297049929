import { useGetUserProfile } from '@leagueplatform/web-common';

const QUERY_CACHE_AND_STALE_TIME = 15 * 1000 * 60;

export const usePreferredFirstName = () => {
  const { data, isLoading, error } = useGetUserProfile({
    staleTime: QUERY_CACHE_AND_STALE_TIME,
    cacheTime: QUERY_CACHE_AND_STALE_TIME,
  });
  const preferredFirstName =
    data?.userProfile?.preferredFirstName ||
    data?.userProfile?.firstName ||
    data?.email ||
    '';

  return { data: { preferredFirstName }, isLoading, error };
};
