import { QueryParamModal } from '@premera-web/shared-module';
import { QUERY_PARAM_KEYS } from 'common/constants';
import { useModalContentConfig } from 'hooks/use-query-modal-content.hook';

export const AppRootModal = () => {
  const contentConfig = useModalContentConfig();

  return (
    <QueryParamModal
      queryModalContentConfig={contentConfig}
      queryParamKey={QUERY_PARAM_KEYS.APP_ROOT_MODAL}
    />
  );
};
