import { Suspense } from 'react';
import { BrowserRouter } from '@leagueplatform/routing';
import { ReactQueryDevtools } from 'react-query/devtools';

import { useOnlineStatus } from '@leagueplatform/web-common';
import { ErrorState } from '@premera-web/shared-module';
import { LoadingIndicator } from '@leagueplatform/web-common-components';
import League from '@leagueplatform/core';
import { ObservabilityErrorBoundary } from '@leagueplatform/observability';
import { AppRootModal } from 'components/app-root-modal/app-root-modal.component';
import AppRoutes from 'components/AppRoutes';
import { config, routesConfig } from './config';

League.overwriteRoutePaths(routesConfig);

export function App() {
  const { isOffline, isOnline } = useOnlineStatus();

  return (
    <League.LeagueProvider config={config}>
      <ObservabilityErrorBoundary
        fallback={
          <ErrorState
            isFullPage
            headingId="STR_ERROR_STATE_HEADING_GENERIC"
            bodyId="STR_ERROR_STATE_BODY_API"
            buttonId="STR_RELOAD"
          />
        }
        errorContext={{
          context: { Context: { location: 'Root App ErrorBoundary' } },
        }}
      >
        <Suspense fallback={<LoadingIndicator />}>
          {isOffline && (
            <ErrorState
              isFullPage
              headingId="STR_ERROR_STATE_HEADING_GENERIC"
              bodyId="STR_ERROR_STATE_BODY_OFFLINE"
              hideButton
            />
          )}
          {isOnline && (
            <BrowserRouter>
              <AppRoutes />
              <AppRootModal />
            </BrowserRouter>
          )}
          <ReactQueryDevtools />
        </Suspense>
      </ObservabilityErrorBoundary>
    </League.LeagueProvider>
  );
}
