import React from 'react';
import { GDSColor, StackLayout } from '@leagueplatform/genesis-core';
import {
  AssetLink,
  AssetLinkProps,
} from '../../asset-links/asset-link.component';
import {
  FooterLinkItems,
  FooterLinkProps,
} from './footer-link-items.component';

const FooterLinksLayoutWrapper = ({ children }: any) => (
  <StackLayout
    orientation={{
      '@initial': 'vertical',
      '@tablet': 'horizontal',
      '@laptop': 'horizontal',
      '@desktop': 'horizontal',
    }}
    horizontalAlignment="center"
    verticalAlignment={{
      '@initial': 'top',
      '@tablet': 'spaceBetween',
      '@laptop': 'spaceBetween',
      '@desktop': 'spaceBetween',
    }}
    css={{
      marginBottom: '$one',
      flexWrap: 'wrap',
      width: '100%',
      '@tablet': {
        flexWrap: 'nowrap',
      },
      '@laptop': {
        flexWrap: 'nowrap',
      },
      '@desktop': {
        flexWrap: 'nowrap',
      },
    }}
  >
    {children}
  </StackLayout>
);

export const FooterLinksAndIconsSection = ({
  footerLinks,
  secondaryFooterLinks,
  footerIcons,
  textColor,
}: {
  footerLinks: FooterLinkProps[];
  secondaryFooterLinks?: FooterLinkProps[];
  footerIcons?: AssetLinkProps[];
  textColor?: GDSColor;
}) => (
  <FooterLinksLayoutWrapper>
    {/* Footer Links Section */}
    <StackLayout
      spacing="$one"
      orientation={{
        '@initial': 'horizontal',
        '@tablet': 'vertical',
        '@laptop': 'vertical',
        '@desktop': 'vertical',
      }}
      horizontalAlignment={{
        '@initial': 'stretch',
        '@tablet': 'start',
        '@laptop': 'start',
        '@desktop': 'start',
      }}
      css={{
        flexWrap: 'wrap',
        flexBasis: 'auto',
        width: '100%',
      }}
    >
      <StackLayout
        orientation={{
          '@initial': 'vertical',
          '@tablet': 'horizontal',
          '@laptop': 'horizontal',
          '@desktop': 'horizontal',
        }}
        css={{ minWidth: '100px', flexWrap: 'wrap' }}
      >
        <FooterLinkItems
          linkItems={footerLinks}
          styleProps={{
            color: textColor,
            display: 'flex',
            marginRight: 'none',
            marginLeft: 'none',
            marginBottom: '$half',
            justifyContent: 'center',
            fontWeight: '700',
            '@tablet': {
              marginRight: '$two',
              marginBottom: 'none',
              justifyContent: 'start',
            },
            '@laptop': {
              marginRight: '$two',
              marginBottom: 'none',
              justifyContent: 'start',
            },
            '@desktop': {
              marginRight: '$two',
              marginBottom: 'none',
              justifyContent: 'start',
            },
          }}
        />
      </StackLayout>
      <StackLayout
        orientation={{
          '@initial': 'vertical',
          '@tablet': 'horizontal',
          '@laptop': 'horizontal',
          '@desktop': 'horizontal',
        }}
        css={{ minWidth: '100px', flexWrap: 'wrap' }}
      >
        {secondaryFooterLinks && (
          <FooterLinkItems
            linkItems={secondaryFooterLinks}
            styleProps={{
              color: textColor,
              display: 'flex',
              marginRight: 'none',
              marginLeft: 'none',
              marginBottom: '$half',
              textWrap: 'nowrap',
              justifyContent: 'center',
              fontWeight: 'normal',
              '@tablet': {
                marginRight: '$oneAndHalf',
                marginBottom: 'none',
                justifyContent: 'start',
              },
              '@laptop': {
                marginRight: '$oneAndHalf',
                marginBottom: 'none',
                justifyContent: 'start',
              },
              '@desktop': {
                marginRight: '$oneAndHalf',
                marginBottom: 'none',
                justifyContent: 'start',
              },
            }}
          />
        )}
      </StackLayout>
    </StackLayout>

    {/* Footer Icons Section */}
    {!!footerIcons && (
      <StackLayout
        css={{
          flexWrap: 'wrap',
          alignItems: 'center',
          flexBasis: 'auto',
          '@tablet': {
            flexWrap: 'nowrap',
          },
          '@laptop': {
            flexWrap: 'nowrap',
          },
          '@desktop': {
            flexWrap: 'nowrap',
          },
        }}
        orientation="horizontal"
        horizontalAlignment={{
          '@initial': 'center',
          '@tablet': 'end',
          '@laptop': 'end',
          '@desktop': 'end',
        }}
      >
        {footerIcons.map((item) => (
          <AssetLink
            key={item.url}
            src={item.src}
            alt={item.alt}
            url={item.url}
          />
        ))}
      </StackLayout>
    )}
  </FooterLinksLayoutWrapper>
);
