import { IDENTITY_PARTNER_TOKEN_PATH } from '@leagueplatform/sso-util';
import { leagueFetch } from '@leagueplatform/league-fetch';
import { deserialise } from 'kitsu-core';

export type PartnerTokenData = {
  idToken: string;
  accessToken: string;
  partnerId: string;
};

export const getPartnerToken = async (partnerId: string) => {
  const params = new URLSearchParams({ partnerId });

  const res = await leagueFetch(
    `${IDENTITY_PARTNER_TOKEN_PATH}?${params.toString()}`,
  );

  if (!res.ok) {
    throw new Error(`Fetch partner token failed: ${await res.text()}`);
  }
  const responseObject = await res.json();

  const { data }: { data: PartnerTokenData } = deserialise(responseObject);

  if (!data) {
    throw new Error('No access token found in fetch partner token response.');
  }

  return data;
};
