import { Button, Modal } from '@leagueplatform/genesis-core';
import { AppBarItem } from '@leagueplatform/web-common-components';
import React, { useState } from 'react';
import { useIntl } from '@leagueplatform/locales';
import { MobileNavDrawer } from './mobile-nav-drawer.component';
import { MobileNavDrawerLinksConfig, NavItem } from '../nav-types.types';

export const MobileNavBar = ({
  navLinks,
  settingsLinks,
  logo,
}: {
  navLinks: NavItem[];
  settingsLinks: MobileNavDrawerLinksConfig[];
  logo?: React.ReactElement;
}) => {
  const [menuOpen, setMenuOpen] = useState<boolean>(false);
  const { formatMessage } = useIntl();

  return (
    <>
      <AppBarItem margin="auto" paddingLeft="half">
        {logo}
      </AppBarItem>

      <Modal.Root
        open={menuOpen}
        onOpenChange={() => {
          setMenuOpen((previous) => !previous);
        }}
      >
        <Modal.Trigger>
          <Button
            icon="interfaceMenu"
            hideLabel
            quiet
            priority="tertiary"
            size="large"
            css={{
              margin: '$half $quarter',
              color: '$onSurfaceIconPrimary',
            }}
            aria-controls="user_dropdown_menu"
          >
            {formatMessage({ id: 'STR_OPEN_MENU' })}
          </Button>
        </Modal.Trigger>
        <Modal.Content
          id="user_dropdown_menu"
          layout="right"
          css={{
            '.GDS-modal': { maxWidth: 335 },
            '.GDS-modal-content': { paddingLeft: '$one' },
          }}
        >
          <MobileNavDrawer
            closeModal={() => setMenuOpen(false)}
            navLinks={navLinks}
            settingsLinks={settingsLinks}
          />
        </Modal.Content>
      </Modal.Root>
    </>
  );
};
