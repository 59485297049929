export const MASONRY = {
  APP_ID_HOME_PAGE: 'premera_home',
  API_VERSION: 2,
  MODULE_KEY_CLAIMS_HOMEPAGE: 'claims',
  CUSTOM_WIDGET_TYPE_CLAIMS_HOMEPAGE: 'recent_claims_cards',
};

export const TENANT_ID = 'premera';

export const APP_ID = 'premera-web';

export const PARTNER_ID = 'premera';

export const AUTH0_DEFAULT_CLIENT_CONNECTION = 'premera';

export const SENTRY_CONSTANTS = {
  OWNERSHIP_TEAM: 'premera-solutions',
};

export const AUTH0_ERROR_TYPES = {
  LOGIN_REQUIRED: 'login_required',
};

export const QUERY_PARAM_KEYS = {
  APP_ROOT_MODAL: 'modal',
};

export enum APP_ROOT_MODAL_PARAM_VALUES {
  INELIGIBLE = 'ineligible',
  GENERIC_AUTH_ERROR = 'auth_error',
}
