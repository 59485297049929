import { Box } from '@leagueplatform/genesis-core';
import { StatusBannerMasonryWidget } from 'components/status-banner-masonry-widget/status-banner-masonry-widget.component';

export function InjectStatusBannerMasonryWidget() {
  const isClaims = true;

  return (
    <Box
      css={{
        position: 'relative',
        margin: 'auto',
        width: '100%',
        maxWidth: '1400px',
        padding: '$none 56px',
        '@mobile': { padding: '$none $oneAndHalf' },
      }}
    >
      <StatusBannerMasonryWidget isClaims={isClaims} />
    </Box>
  );
}
