import { getModulePath, LEAGUE_MODULE_NAMES } from '@leagueplatform/core';
import { NavItem } from '@premera-web/shared-module';

export enum NavIds {
  HOME = 'STR_HOME',
  BENEFITS = 'STR_BENEFITS',
  CLAIMS = 'STR_CLAIMS',
}

export const useNavLinksList = () => {
  const navLinksList: NavItem[] = [
    {
      to: '/home',
      message_id: NavIds.HOME,
      icon: 'illustrativeHome',
    },
    {
      to: getModulePath(LEAGUE_MODULE_NAMES.wallet),
      message_id: NavIds.BENEFITS,
      icon: 'capabilityWallet',
    },
    {
      to: getModulePath(LEAGUE_MODULE_NAMES.claims),
      message_id: NavIds.CLAIMS,
      icon: 'capabilityClaims',
    },
  ];

  return {
    navLinks: navLinksList,
  };
};
