import { Fragment } from 'react';
import { EVENT_NAME, trackAnalyticsEvent } from '@leagueplatform/analytics';
import {
  Box,
  GDSParagraphTextProps,
  GDSTextActionProps,
  ParagraphText,
  StackItem,
  StackLayout,
  TextAction,
} from '@leagueplatform/genesis-core';
import { useIntl } from '@leagueplatform/locales';
import { useIsAuthenticated } from '@premera-web/shared-module/src/hooks/use-is-authenticated.hook';
import { EXTERNAL_LINKS } from 'common/links';
import { LanguageLinks } from './language-links.config';

const DisclaimerBodyText = ({
  children,
  ...props
}: GDSParagraphTextProps<'p' | 'span'>) => (
  <ParagraphText
    size="xs"
    {...props}
    css={{ color: '$onSurfaceTextReversed', ...props.css }}
  >
    {children}
  </ParagraphText>
);

const ExternalLink = ({ css, children, ...props }: GDSTextActionProps<'a'>) => (
  <TextAction
    as="a"
    target="_blank"
    rel="noreferrer"
    css={{
      color: '$onSurfaceTextReversed !important',
      fontWeight: 'normal !important',
      fontSize: '$caption',
      fontFamily: '$caption',
      lineHeight: '$caption',
      letterSpacing: 'normal',
      ...css,
    }}
    {...props}
  >
    {children}
  </TextAction>
);

export const Disclaimer = () => {
  const { formatMessage } = useIntl();

  const { isAuthenticated } = useIsAuthenticated();

  const handleClick = (eventDetail: string) => {
    if (isAuthenticated) {
      trackAnalyticsEvent(EVENT_NAME.RESOURCE_CLICKED, {
        detail: eventDetail,
        sub_product_area: 'footer',
      });
    }
  };

  return (
    <StackLayout spacing="$one">
      <StackItem>
        <DisclaimerBodyText css={{ fontWeight: '700' }}>
          {formatMessage({ id: 'STR_FOOTER_LANGUAGE_SUPPORT_HEADING' })}
        </DisclaimerBodyText>
        <Box as="span">
          {LanguageLinks.map((language, ind) => (
            <Fragment key={language.lang}>
              <ExternalLink
                href={EXTERNAL_LINKS.LANGUAGES_AND_DISCRIMINATION}
                onClick={() => handleClick(language.label)}
                lang={language.lang}
                dir={language.dir}
              >
                {language.label}
              </ExternalLink>
              {ind < LanguageLinks.length - 1 && (
                <DisclaimerBodyText
                  as="span"
                  aria-hidden="true"
                  css={{
                    margin: '$half',
                    display: 'inline',
                  }}
                >
                  |
                </DisclaimerBodyText>
              )}
            </Fragment>
          ))}
        </Box>
      </StackItem>

      <DisclaimerBodyText>
        {formatMessage({ id: 'STR_DISCRIMINATION_DISCLAIMER_1' })}
        <ExternalLink
          href={EXTERNAL_LINKS.LANGUAGES_AND_DISCRIMINATION}
          onClick={() => handleClick('discrimination disclaimer')}
          css={{ textDecoration: 'underline' }}
        >
          {formatMessage({
            id: 'STR_DISCRIMINATION_DISCLAIMER_2_LINK',
          })}
        </ExternalLink>
        {formatMessage({ id: 'STR_DISCRIMINATION_DISCLAIMER_3' })}
        <ExternalLink
          href={EXTERNAL_LINKS.LANGUAGES_AND_DISCRIMINATION}
          onClick={() => handleClick('contact us')}
          css={{ textDecoration: 'underline' }}
        >
          {formatMessage({
            id: 'STR_DISCRIMINATION_DISCLAIMER_4_CONTACT_US_LINK',
          })}
        </ExternalLink>
        {formatMessage({ id: 'STR_DISCRIMINATION_DISCLAIMER_4' })}
      </DisclaimerBodyText>
      <DisclaimerBodyText css={{ color: '$decorativeBrandSecondaryBright' }}>
        {formatMessage({ id: 'STR_FOOTER_COPYRIGHT' })}
      </DisclaimerBodyText>
    </StackLayout>
  );
};
