import { lazy } from 'react';

export const Home = lazy(() => import('pages/home/Home.page'));

export const WalletRoutes = lazy(() =>
  import('@leagueplatform/wallet').then((module) => ({
    default: module.WalletRoutes,
  })),
);

export const ClaimsRoutes = lazy(() =>
  import('pages/claims-routes-wrapper.page').then((module) => ({
    default: module.ClaimsRoutesWrapper,
  })),
);
