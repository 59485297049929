export const LanguageLinks = [
  {
    label: 'Español',
    lang: 'es',
  },
  {
    label: '中文',
    lang: 'zh',
  },
  {
    label: 'Tiếng Việt',
    lang: 'vi',
  },
  {
    label: '한국어',
    lang: 'ko',
  },
  {
    label: 'Pусский',
    lang: 'ru',
  },
  {
    label: 'Tagalog',
    lang: 'tl',
  },
  {
    label: 'Українськa',
    lang: 'uk',
  },
  {
    label: 'ភាសាខ្មែរ',
    lang: 'km',
  },
  {
    label: '日本語',
    lang: 'ja',
  },
  {
    label: 'አማሪኛ',
    lang: 'am',
  },
  {
    label: 'Oromoo',
    lang: 'om',
  },
  {
    label: 'العربية',
    lang: 'ar',
    dir: 'rtl',
  },
  {
    label: 'ਪੰਜਾਬੀ',
    lang: 'pa',
  },
  {
    label: 'Deutsch',
    lang: 'de',
  },
  {
    label: 'ລາວ',
    lang: 'lo',
  },
  {
    label: 'Kreyòl Ayisyen',
    lang: 'ht',
  },
  {
    label: 'Français',
    lang: 'fr',
  },
  {
    label: 'Polski',
    lang: 'pl',
  },
  {
    label: 'Português',
    lang: 'pt',
  },
  {
    label: 'Italiano',
    lang: 'it',
  },
  {
    label: 'فارسی',
    lang: 'fa',
    dir: 'rtl',
  },
];
