import React from 'react';
import { useUrlParam } from '@leagueplatform/web-common';
import { PopUpMessageModal } from '../popup-message-modal/popup-message-modal.component';
import { QueryParamModalProps } from './query-param-modal.types';

export const QueryParamModal = ({
  queryParamKey,
  queryModalContentConfig,
}: QueryParamModalProps) => {
  const { removeParam, urlParam } = useUrlParam(queryParamKey);

  if (!urlParam || !queryModalContentConfig[urlParam]) {
    return null;
  }

  const content = queryModalContentConfig[urlParam];

  return (
    <PopUpMessageModal
      content={content}
      modalRootProps={{
        open: !!urlParam,
        onOpenChange: removeParam,
      }}
    />
  );
};
