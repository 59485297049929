import {
  LeagueConfig,
  LEAGUE_MODULE_NAMES,
  Environment,
} from '@leagueplatform/core';
import { ASSET_OVERRIDES } from 'common/asset-overrides';
import {
  APP_ID,
  AUTH0_DEFAULT_CLIENT_CONNECTION,
  TENANT_ID,
} from 'common/constants';
import { LoadingSpinner } from 'components/loading-spinner.component';
import EN_STRING_OVERRIDES from 'locales/en.json';
import { premeraTheme } from 'theme/theme';
import { InjectStatusBannerMasonryWidget } from 'components/InjectComponent';

export const routesConfig = {
  [LEAGUE_MODULE_NAMES.wallet]: '/benefits',
};

const {
  VITE_SEGMENT_KEY,
  VITE_WS_API_URL,
  VITE_API_URL,
  VITE_LEGACY_REST_API_URL,
  VITE_CONTENT_URL,
  VITE_AUTH0_DOMAIN,
  VITE_AUTH0_CLIENT_ID,
  VITE_APP_ENV,
} = import.meta.env;

const authConfig = {
  domain: VITE_AUTH0_DOMAIN!,
  client_id: VITE_AUTH0_CLIENT_ID!,
  redirect_uri: window.location.origin,
  connection: AUTH0_DEFAULT_CLIENT_CONNECTION,
};

const analyticsConfig = {
  segmentKey: VITE_SEGMENT_KEY,
};

const apiConfig = {
  wsUrl: VITE_WS_API_URL!,
  url: VITE_API_URL!,
  legacyRestApi: VITE_LEGACY_REST_API_URL!,
};

const appConfig = {
  contentUrl: VITE_CONTENT_URL!,
  appEnvironment: VITE_APP_ENV as Environment,
};

const customMethodsConfig = {
  handleLink: async (url: string) => {
    await window.open(url, '_blank', 'noreferrer');
  },
};

export const config: LeagueConfig = {
  core: {
    clientId: APP_ID,
    tenantId: TENANT_ID,
    auth: { clientOptions: authConfig, idleSessionLifetimeHours: 48 },
    analytics: analyticsConfig,
    api: apiConfig,
    ...appConfig,
    customMethods: customMethodsConfig,
    routing: { isUsingLeagueRouting: true },
    ui: {
      theme: premeraTheme,
      components: {
        loading: {
          element: <LoadingSpinner />,
          text: false,
        },
      },
    },
    i18n: {
      stringOverrides: {
        default: EN_STRING_OVERRIDES,
        en: EN_STRING_OVERRIDES,
      },
    },
  },
  assets: {
    overrides: ASSET_OVERRIDES,
  },

  claims: {
    featureFlags: {
      shouldShowClaimDetails: true,
      shouldShowDownloadClaims: false,
    },
    slots: {
      Banner: InjectStatusBannerMasonryWidget,
    },
    showClaimsSummaryBackButton: false,
    showTotalCostOverviewAndAdditionalResources: true,
    filterableProperties: {
      status: ['adjusted', 'pending', 'approved', 'partially_paid'],
    },
    showClaimStatusDefinitions: true,
  },
  wallet: {
    showLandingPageHeaderBranding: true,
  },
};
