import { useCallback } from 'react';
import { useIntl } from '@leagueplatform/locales';
import { Auth0Error, LandingPage } from '@premera-web/shared-module';
import { useHistory } from '@leagueplatform/routing';
import landingBackgroundImage from 'assets/landing-page-illustration.jpg';
import logoWithText from 'assets/logo-with-text.svg';
import { APP_MANAGED_ROUTES } from 'common/app-managed-routes';
import {
  APP_ROOT_MODAL_PARAM_VALUES,
  QUERY_PARAM_KEYS,
} from 'common/constants';
import { captureAuthenticationError } from 'utils/sentry-helpers';

export const PreLoginLanding = () => {
  const { formatMessage } = useIntl();
  const history = useHistory();

  const handleError = useCallback(
    (authError: Auth0Error) => {
      captureAuthenticationError(authError);
      history.push(
        `?${QUERY_PARAM_KEYS.APP_ROOT_MODAL}=${APP_ROOT_MODAL_PARAM_VALUES.GENERIC_AUTH_ERROR}`,
      );
    },
    [history],
  );

  return (
    <LandingPage
      backgroundImage={landingBackgroundImage}
      logoImage={logoWithText}
      primaryButton={{
        buttonText: formatMessage({ id: 'STR_SIGN_IN' }),
        onClick: () => history.push(APP_MANAGED_ROUTES.SIGN_IN),
      }}
      onAuthenticated={(appState) =>
        history.push(appState?.redirectPath || APP_MANAGED_ROUTES.HOME)
      }
      onAuthError={handleError}
    />
  );
};
