import {
  captureAppError,
  captureAppMessage,
} from '@leagueplatform/observability';
import { AUTH0_ERROR_TYPES, SENTRY_CONSTANTS } from 'common/constants';
import { isIneligibleAuthError } from './auth-error-helpers';

export const captureAuthenticationError = (error: {
  type: string;
  description: string | null;
}) => {
  if (
    error.type &&
    error.type.toLowerCase().includes(AUTH0_ERROR_TYPES.LOGIN_REQUIRED)
  ) {
    // If the error message is due to the user timing out, then we would like to capture this
    // as a warning on Sentry, all other use cases should be handled as an error
    captureAppMessage(`Auth0 Error - ${error.type}`, {
      context: {
        Context: {
          info: `User has been logged out due to inactivity, a new login is required - type: ${error.type}, description: ${error.description}`,
        },
      },
      ownershipTeam: SENTRY_CONSTANTS.OWNERSHIP_TEAM,
      severityLevel: 'warning',
    });
  } else if (isIneligibleAuthError(error)) {
    captureAppError(new Error(error.type), {
      errorName: `Auth0 Error - Missing Member Key`,
      context: {
        Context: {
          info: `User is missing member key in id token claims.`,
        },
      },
      ownershipTeam: SENTRY_CONSTANTS.OWNERSHIP_TEAM,
    });
  } else {
    captureAppError(new Error(error.type), {
      errorName: `Auth0 Error - ${error.type}`,
      context: {
        Context: {
          info: `An authentication error has occurred - type: ${error.type}, description: ${error.description}`,
        },
      },
      ownershipTeam: SENTRY_CONSTANTS.OWNERSHIP_TEAM,
    });
  }
};
