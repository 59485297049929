import { useUrlParam } from '@leagueplatform/web-common';
import { QueryModalContentConfig } from '@premera-web/shared-module';
import ErrorImage from 'assets/error-rubiks-cube.svg';
import {
  APP_ROOT_MODAL_PARAM_VALUES,
  QUERY_PARAM_KEYS,
} from 'common/constants';

export const useModalContentConfig = (): QueryModalContentConfig => {
  const { removeParam } = useUrlParam(QUERY_PARAM_KEYS.APP_ROOT_MODAL);

  return {
    [APP_ROOT_MODAL_PARAM_VALUES.GENERIC_AUTH_ERROR]: {
      image: ErrorImage,
      headingId: 'STR_GENERIC_AUTH_ERROR_MODAL_HEADING',
      bodyId: 'STR_GENERIC_AUTH_ERROR_MODAL_BODY',
      cancelButton: {
        id: 'STR_CLOSE',
        onClick: removeParam,
      },
      innerContainerStyling: {
        alignItems: 'center',
        textAlign: 'center',
      },
      modalContentProps: {
        onInteractOutside: (e) => e.preventDefault(),
      },
    },
  };
};
